import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Customers from "../components/customers"
import Testimonials from "../components/testimonials"
import { JobFeature } from "../components/job-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import QuestionAndAnswer from "../components/question-and-answer"
import ActionForm from "../components/action-form"

const IndoorAnonymization = () => (
  <Layout>
    <SEO
      title="Blur screens, whiteboards, documents and more on indoor imagery | Celantur"
      description="Maximal privacy for indoor imagery: Automatically redact doors signs, computer screens, TVs, whiteboards, pinwalls, documents, post-its, portraits, flipcharts, floor plans."
    />

    <section className="container pt-4 pt-md-11 pb-3 pb-sm-3 bg-gradient">
      <span className="badge badge-pill badge-success-soft mb-3">
        <span className="h6 text-uppercase">Early Access</span>
      </span>

      <h1 className="display-4 text-center text-md-left font-weight-bold">
        <span className="text-primary">Maximal Privacy for Indoor Imagery</span>
      </h1>

      <p className="lead text-center text-md-left mb-5">
        The world's first comprehensive solution to blur sensitive and personal information on indoor images:
      </p>
      <div className="row">
        <div className="col">
          <ul className="list-unstyled font-weight-bold">
            <li>
              <JobFeature>Door signs</JobFeature>
            </li>
            <li>
              <JobFeature>Displays (computer screens, TVs, projections)</JobFeature>
            </li>
            <li>
              <JobFeature>Whiteboards</JobFeature>
            </li>
            <li>
              <JobFeature>Pinwalls</JobFeature>
            </li>
            <li>
              <JobFeature>Documents</JobFeature>
            </li>
          </ul>
        </div>
        <div className="col">
          <ul className="list-unstyled font-weight-bold">
            <li>
              <JobFeature>Post-its</JobFeature>
            </li>
            <li>
              <JobFeature>Portraits</JobFeature>
            </li>
            <li>
              <JobFeature>Flipcharts</JobFeature>
            </li>
            <li>
              <JobFeature>Floor plans</JobFeature>
            </li>
            <li>
              <JobFeature>Faces and persons</JobFeature>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section className="pt-5 pb-5">
      <ActionForm
        source="indoor-anonymization"
        headline="Request Early Access."
        placeholderInput="Tell us about your use case (optional)"
        labelSubmitButton="Request Early Access"
      />
    </section>

    <section className="pt-5 pb-5">
      <div className="container">
        <h2 className="font-weight-bold text-center">FAQ</h2>
        <QuestionAndAnswer question="What is indoor imagery?" isOpen={true}>
          Images which are taken indoors - usually for documentation, mapping and surveying purposes. E.g. in factories,
          offices, kindergartens, schools, universities, hospitals, public buildings, shopping centers and many more.
        </QuestionAndAnswer>
        <QuestionAndAnswer question="How can I use the solution?" isOpen>
          At first, the new indoor anonymization functionality will be part of{" "}
          <Link to="/container/">Celantur Container</Link>. You can deploy it on-premise or in any cloud environment.
        </QuestionAndAnswer>
        <QuestionAndAnswer question="When will it be available?" isOpen>
          Our team is currently working on an early version of the solution. Let us know if you want to become one of
          the early adaptors.
        </QuestionAndAnswer>
        <QuestionAndAnswer question="How do I get early access?" isOpen>
          Please contact us with the form on the top of this page. Alternatively, send an email to hello@celantur.com.
        </QuestionAndAnswer>
        <QuestionAndAnswer question="Why is it necessary to anonymize those objects?" isOpen>
          Objects within a buildings can reveal personal or sensitive information, e.g. names of employees or patients,
          phone numbers, business secrets, etc. Privacy and security concerns, as well as legal obligations, are driving
          the need for safeguarding this type of information on images.
        </QuestionAndAnswer>
        <QuestionAndAnswer question="How can I use the solution?" isOpen>
          We plan to ship it as a <Link to="/container/">containerized application</Link>, which can be deployed
          on-premise or in any cloud environment. Other delivery options (API, Edge deployment) are possible as well.
          Let us know what you'd prefer.
        </QuestionAndAnswer>
        <QuestionAndAnswer question="How does my business benefit?">
          You'll get early access to a new type of indoor object redaction capabilities.
        </QuestionAndAnswer>
        <QuestionAndAnswer question="Aren't there existing solution on the market?">
          We haven't found any! Additionally, we received a considerable amount of requests asking for indoor object
          redaction capabilities, which led us to the conclusion that there's an unsatisfied market demand.
        </QuestionAndAnswer>
      </div>
    </section>

    <section className="pt-5 pb-8">
      <Customers />
    </section>

    <Testimonials navvis riegl />

    <section className="container my-5 text-center">
      {" "}
      {/* CTA */}
      <Link to="/contact/" role="button" className="btn btn-lg btn-success my-2 event-contact">
        Contact Us
        <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
      </Link>
    </section>
  </Layout>
)

export default IndoorAnonymization
